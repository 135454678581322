.hidden {
  display: none !important;
}

#splashimage {
  z-index: 998;
  height: 100%;
  position: relative;
  /* background-color: #7505a5; */
  background-image: url('./assets/bg.png');
  background-size: cover;
  padding: 24px;
  
}

.start-btn{
  /*z-index: 999;*/
  width: 50vw;
  height: auto;
  position: absolute;
  bottom: 30vh;
  left: 25vw;
  background-color: #FFF;
  text-align: center;
  color: #8936ff;
  border-radius: 100px;
}

h2 {
  font-size: 18px;
  text-align: center;
  font-family: 'poppins';
}

#loadBackground{
  background-color: white;
}
#requestingCameraPermissions {
  color: white !important;
  background-color: white !important;
}
#requestingCameraIcon {
  /* This changes the image from white to black */
  filter: invert(1);
}

.prompt-box-8w {
  background-color: white;
  color: #00FF00;
}

.prompt-button-8w {
  background-color: #fc4f13;
}

.button-primary-8w {
  background-color: #fc4f13 !important;
}

.position-slogan{
  position: absolute;
  right: 2vw;
  bottom: 10vh;
  height: 50px;
}